'use client';

import { teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

export const appTheme = createTheme({
  palette: {
    ...palette,
    tertiary: palette.augmentColor({
      name: 'tertiary',
      color: { main: teal[500] },
    }),
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  typography,
  customShadows: shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 500,
          borderRadius: theme.spacing(0.5),
          bgColor: 'blue',
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingX: 1.5,
          paddingY: 1,
          '&.Mui-selected': { backgroundColor: 'white' },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: ({ theme }) => ({
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.dark,
          padding: '24px',
        }),
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.dark,
            },
          },
          '&:hover': {
            backgroundColor: 'menuSelectedBack',
            color: theme.palette.secondary.dark,
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.dark,
            },
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          minWidth: '36px',
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) => ({
          color: theme.palette.text.dark,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: '0.875rem',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.grey[50],
          borderRadius: theme.spacing(1),
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.secondary.light,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        }),
        input: ({ theme }) => ({
          fontWeight: 500,
          background: theme.palette.grey[50],
          padding: '15.5px 14px',
          borderRadius: theme.spacing(1),
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        }),
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: ({ theme }) => ({
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
        }),
        mark: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        }),
        valueLabel: ({ theme }) => ({
          color: theme.palette.secondary.light,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAutocomplete-tag': {
            background: theme.palette.secondary.light,
            borderRadius: theme.spacing(1),
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color: theme.palette.secondary[200],
            },
          },
        }),
        popper: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontWeight: 500,
          borderRadius: '4px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        filled: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          '&:focus': {
            backgroundColor: theme.palette.secondary.main,
          },
        }),
        iconFilled: {
          color: 'white',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.dark,
          background: theme.palette.secondary[200],
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    // @ts-ignore
    MuiTimelineContent: {
      styleOverrides: {
        // @ts-ignore
        root: ({ theme }) => ({
          color: theme.palette.text.dark,
          fontSize: '16px',
        }),
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        // @ts-ignore
        tabs: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          '& .MuiTabs-flexContainer': {
            borderColor: theme.palette.secondary[200],
          },
          '& .MuiTab-root': {
            color: theme.palette.grey[900],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.secondary.dark,
          },
          '& .Mui-selected': {
            color: theme.palette.secondary.dark,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: ({ theme }) => ({
          borderBottom: '1px solid',
          borderColor: theme.palette.grey[200],
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey[200],
          '&.MuiTableCell-head': {
            fontSize: '0.875rem',
            color: theme.palette.grey[900],
            fontWeight: 500,
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          color: theme.palette.background.paper,
          background: theme.palette.text.secondary,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        slotProps: {
          panel: {
            sx: {
              '& .MuiDataGrid-panelWrapper': {
                maxWidth: 'calc(100vw - 4rem)',
              },
            },
          },
        },
      },
    },
  },
});
