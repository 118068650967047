import createPalette from '@mui/material/styles/createPalette';

export const palette = createPalette({
  primary: {
    main: '#2196f3',
    dark: '#1e88e5',
    light: '#e3f2fd',
    800: '#1565c0',
  },
  secondary: {
    main: '#5403ff',
    dark: '#4b02e5',
    light: '#f6e5ff',
    200: '#DCCCFF',
    800: '#7635ff',
  },
  tertiary: {
    main: '#009688',
    dark: '#00695f',
    light: '#e0f2f1',
  },
  error: {
    light: '#ef9a9a',
    main: '#f44336',
    dark: '#c62828',
  },
  warning: {
    light: '#fff8e1',
    main: '#ffe57f',
    dark: '#ffc107',
    400: '#FFE266',
  },
  success: {
    light: '#71ff98',
    main: '#06b600',
    dark: '#004d15',
  },
  grey: {
    50: '#f8fafc',
    100: '#eef2f6',
    200: '#dfdfdf',
    300: '#cdd5df',
    500: '#697586',
    600: '#4b5565',
    700: '#364152',
    800: '#223344',
    900: '#121926',
  },
  text: {
    primary: '#364152',
    secondary: 'black',
    dark: '#121926',
    hint: '#eef2f6',
  },
  divider: '#e3e8ef',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    purple: '#d0bfff',
    liteGrey: '#f7f5f2',
  },
});
